import React from "react";
import "./TutorPage.css";
import logo from "./seneca-logo.png";

import { useIntercom } from "react-use-intercom";

const TutorPage = () => {
  const { boot, show } = useIntercom();
  boot();
  return (
    <div className="container">
      <div>
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      <h2>Your personal tutor</h2>
      <p>
        Click below to ask a question in any of your subjects. One of our tutors
        will get back to you as soon as they can!
      </p>
      <button onClick={show}>Ask a question</button>
    </div>
  );
};

export default TutorPage;
