import "./App.css";
import TutorPage from "./TutorPage";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/tutor" element={<TutorPage />} />

        <Route path="/" element={<TutorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
